<template>
  <b-container fluid>
    <b-overlay :show="loadingState">
      <b-row>
        <b-col lg="12" sm="12">
          <iq-card>
            <template>
                <b-row>
                  <b-col lg="6" sm="6">
                    <b-row>
                      <b-col lg="6" sm="12">
                        <p class="text-dark">{{ $t('seedFer.org_name') }} : </p>
                      </b-col>
                      <b-col lg="6" sm="12">
                         <p class="text-dark capitalize">{{ getDetailData(details.org_id, 'org') }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="6" sm="12">
                        <p class="text-dark capitalize">{{ $t('procurement.company') }}{{ '/'}}{{ $t('procurement.dealer') }} : </p>
                      </b-col>
                      <b-col lg="6" sm="12">
                         <p class="text-dark capitalize">{{ details.type === 1 ? $t('procurement.company') : $t('procurement.dealer') }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="6" sm="12">
                        <p class="text-dark capitalize">{{ $t('globalTrans.name') }} : </p>
                      </b-col>
                      <b-col lg="6" sm="12">
                         <p class="text-dark capitalize">{{ details.company_name }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="6" sm="12">
                        <p class="text-dark capitalize">{{ $t('seedFer.sales_center') }} : </p>
                      </b-col>
                      <b-col lg="6" sm="12">
                         <p class="text-dark capitalize">{{ getDetailData(details.sale_center_id, 'salecenter') }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="6" sm="12">
                        <p class="text-dark">{{ $t('globalTrans.date') }} : </p>
                      </b-col>
                      <b-col lg="6" sm="12">
                         <p class="text-dark">{{ details.current_date | dateFormat }}</p>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col lg="6" sm="6">
                      <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('fertilizerConfig.fertilizer_type') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark capitalize">{{ getDetailData(details.fertilizer_type_id, 'far_type') }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('fertilizerConfig.fertilizer_name') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark capitalize">{{ getDetailData(details.fertilizer_name_id, 'far_name') }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('irrigation_config.unit') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ getDetailData(details.unit_id, 'unit') }}</p>
                      </b-col>
                    </b-row>
                    <b-row>
                      <b-col lg="5" sm="12">
                        <p class="text-dark">{{ $t('pump_install.quantity') }} : </p>
                      </b-col>
                      <b-col lg="7" sm="12">
                         <p class="text-dark">{{ $n(details.quantity) }}</p>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
            </template>
          </iq-card>
        </b-col>
      </b-row>
      </b-overlay>
    </b-container>
</template>
<script>
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { purchaseRequistiondetails } from '../../api/routes'
export default {
  name: 'FormLayout',
  props: ['id'],
  created () {
    if (this.id) {
      const tmp = this.getDetails()
      Object.freeze(tmp)
      this.list = tmp
    }
  },
  data () {
    return {
        details: [],
        list: []
    }
  },
  computed: {
    loading () {
      return this.$store.state.commonObj.loading
    },
    listReload () {
      return this.$store.state.commonObj.listReload
    },
    loadingState () {
      if (this.listReload || this.loading) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    async getDetails () {
        this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: true })
      const result = await RestApi.getData(seedFertilizerServiceBaseUrl, `${purchaseRequistiondetails}/${this.id}`)
      if (result.success) {
          this.details = result.data
      }
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    getDetailData (Id, key) {
        if (Id) {
            var data
            switch (key) {
                case 'org':
                    data = this.$store.state.commonObj.organizationProfileList.find(doc => doc.value === Id)
                    break
                case 'far_type':
                    data = this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.find(item => item.value === Id)
                    break
                case 'far_name':
                    data = this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.find(item => item.value === Id)
                    break
                case 'unit':
                    data = this.$store.state.SeedsFertilizer.commonObj.measurementUnitList.find(item => item.value === Id)
                    break
                case 'fiscalyear':
                     data = this.$store.state.commonObj.fiscalYearList.find(item => item.value === Id)
                    break
                case 'salecenter':
                     data = this.$store.state.SeedsFertilizer.commonObj.SalesCenterList.find(item => item.value === Id)
                    break
                default:
                    break
            }
            return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
        } else {
            return null
        }
    }
  }
}
</script>
<style>
  .hidden_header {
    display: none
  }
  .card-border {
    border: 1px solid #b9bdc1;
    box-shadow: 1px 1px 6px -1px grey;
    background-color: #dee2e6;
  }
  [dir=ltr][mode=light] .card-body {
    flex: 1 1 auto;
    min-height: 1px;
    padding: 10px;
  }
  .report-name{
    font-weight: bold !important;
    text-transform: uppercase;
  }
  .my-btn{
    padding: 2px !important;
  }
</style>
