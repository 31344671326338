<template>
    <b-container fluid>
        <iq-card>
             <template v-slot:body>
                <b-row>
                    <b-col class="col-lg-6">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            :label="$t('seedFer.fiscal_year')"
                            label-for="id"
                        >
                            <v-select name="godown_Info"
                                v-model="search.fiscal_year_id"
                                label="text"
                                :options= fiscalYearList
                            />
                        </b-form-group>
                    </b-col>
                    <b-col class="col-lg-6">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            :label="$t('seedFer.sales_center')"
                            label-for="sale_center_id"
                        >
                            <v-select name="godown_Info"
                                v-model="search.sale_center_id"
                                label="text"
                                :options= salesCenterList
                                id="sale_center_id"
                            />
                        </b-form-group>
                    </b-col>
                    <b-col class="col-lg-6">
                        <b-form-group
                        class="row"
                        label-cols-sm="4"
                        :label="$t('fertilizerConfig.fertilizer_name')"
                        label-for="division_id"
                        >
                        <v-select name="godown_Info"
                             v-model="search.fertilizer_name_id"
                            label="text"
                            :options="fertilizerNameList"
                        />
                        </b-form-group>
                    </b-col>
                    <b-col cols="12">
                        <b-button class="float-right" type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
                    </b-col>
                </b-row>
            </template>
        </iq-card>
        <b-row>
            <b-col md="12">
                <iq-card>
                    <template v-slot:headerTitle>
                        <h4 class="card-title">{{$t('seedFer.purchase_requisition')}}</h4>
                    </template>
                    <template v-slot:headerAction1>

                    </template>
                    <template v-slot:headerAction>
                        <b-button class="btn_add_new" variant="primary" v-b-modal.modal-4 @click="resetId">
                             <i class="fas fa-plus"></i>{{  $t('globalTrans.add_new') }}
                        </b-button>
                    </template>
                    <template v-slot:body>
                        <b-overlay :show="loadingState">
                            <b-row>
                                <b-col md="12" class="table-responsive">
                                    <b-table thead-class="table_head" striped bordered hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                                        <template v-slot:cell(index)="data">
                                            {{ $n(data.index + pagination.slOffset) }}
                                        </template>
                                        <template v-slot:cell(fiscal_year_id)="data">
                                            {{ getPurchaseData(data.item.fiscal_year_id, 'fiscalYear') }}
                                        </template>
                                        <template v-slot:cell(sale_center_id)="data">
                                            {{ getPurchaseData(data.item.sale_center_id, 'salesCenter') }}
                                        </template>
                                        <template v-slot:cell(fertilizer_type_id)="data">
                                            {{ getPurchaseData(data.item.fertilizer_type_id, 'fertilizerType') }}
                                        </template>
                                        <template v-slot:cell(fertilizer_name_id)="data">
                                            {{ getPurchaseData(data.item.fertilizer_name_id , 'fertilizerName') }}
                                        </template>
                                        <template v-slot:cell(unit_id)="data">
                                            {{ getPurchaseData(data.item.unit_id , 'unitName') }}
                                        </template>
                                        <template v-slot:cell(quantity)="data">
                                            {{ $n(data.item.quantity, { useGrouping: false }) }}
                                        </template>
                                        <template v-slot:cell(action)="data">
                                            <a href="javascript:" title="View" class="btn_table_action table_action_view" v-b-modal.modal-5 @click="view(data.item)"><i class="fas fa-eye"></i></a>
                                            <a href="javascript:" title="Approve" class="btn_table_action table_action_status" v-if="data.item.status == 1" @click="accept(data.item)"><i class="fas fa-check"></i></a>
                                            <a href="javascript:" title="Reject" class="btn_table_action table_action_toggle" v-if="data.item.status == 1" @click="remove(data.item)"><i class="fas fa-times"></i></a>
                                        </template>
                                        <template v-slot:cell(status)="data">
                                            <span class="badge badge-info" v-if="data.item.status == 1">{{$t('globalTrans.pending')}}</span>
                                            <span class="badge badge-success" v-else-if="data.item.status == 2">{{$t('globalTrans.approve')}}</span>
                                            <span class="badge badge-danger" v-else>{{$t('globalTrans.reject')}}</span>
                                        </template>
                                    </b-table>
                                    <b-pagination
                                        align="center"
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                        />
                                </b-col>
                            </b-row>
                        </b-overlay>
                    </template>
                </iq-card>
            </b-col>
        </b-row>
        <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Form :id="editItemId" :key="editItemId"/>
        </b-modal>
        <b-modal id="modal-5" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
            <Detail :id="editItemId" :key="editItemId"/>
        </b-modal>
    </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import Form from './Form'
import Detail from './Details'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { purchaseRequistionlist, purchaseRequistionaccept, purchaseRequistionreject } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
    mixins: [ModalBaseMasterList],
    components: {
        Form, Detail
    },
    data () {
        return {
            search: {
                fiscal_year_id: '',
                id: 0,
                sale_center_id: '',
                fertilizer_name_id: '',
                dealer_com_farmer_id: ''
            },
            districtList: [],
            upazilaList: []
        }
    },
    computed: {
        formTitle () {
            return (this.editItemId === 0) ? this.$t('seedFer.purchase_requisition') + ' ' + this.$t('globalTrans.entry') : this.$t('seedFer.purchase_requisition') + ' ' + this.$t('globalTrans.update')
        },
        columns () {
            const labels = [
                { label: this.$t('globalTrans.sl_no'), class: 'text-center' },
                { label: this.$t('seedFer.fiscal_year'), class: 'text-center' },
                { label: this.$t('seedFer.org_name'), class: 'text-center' },
                { label: this.$t('seedFer.sales_center'), class: 'text-center' },
                { label: this.$t('fertilizerConfig.fertilizer_type'), class: 'text-center' },
                { label: this.$t('fertilizerConfig.fertilizer_name'), class: 'text-center' },
                { label: this.$t('irrigation_config.unit'), class: 'text-center' },
                { label: this.$t('pump_install.quantity') + '[' + this.$t('fertilizerSales.m_ton') + ']', class: 'text-center' },
                { label: this.$t('globalTrans.status'), class: 'text-center' },
                { label: this.$t('globalTrans.action'), class: 'text-center' }
            ]

            /*eslint-disable */
            let keys = []

            if (this.$i18n.locale === 'bn') {
                keys = [
                { key: 'index' },
                { key: 'fiscal_year_id' },
                { key: 'org_name_bn' },
                { key: 'sale_center_id' },
                { key: 'fertilizer_type_id' },
                { key: 'fertilizer_name_id' },
                { key: 'unit_id' },
                { key: 'quantity' },
                { key: 'status' },
                { key: 'action' }
                ]
            } else {
                keys = [
                { key: 'index' },
                { key: 'fiscal_year_id' },
                { key: 'org_name' },
                { key: 'sale_center_id' },
                { key: 'fertilizer_type_id' },
                { key: 'fertilizer_name_id' },
                { key: 'unit_id' },
                { key: 'quantity' },
                { key: 'status' },
                { key: 'action' }
                ]
            }

            return labels.map((item, index) => {
                return Object.assign(item, keys[index])
            })
        },
        fiscalYearList: function () {
        const fiscalYears = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
        const tempData = fiscalYears.map(item => {
            if (this.$i18n.locale === 'bn') {
            return { text: item.text_bn, value: item.value }
            } else {
            return { text: item.text_en, value: item.value }
            }
        })
        return tempData
        },
        salesCenterList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.salesCenterList.filter(item => item.status === 1)
        },
        fertilizerNameList: function () {
        return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.filter(item => item.status === 1)
        }
    },
    async created () {
        this.loadData()
    },
    mounted () {
        core.index()
    },
    methods: {
        getDistrictList (divisionId = null) {
            const districtList = this.$store.state.commonObj.districtList.filter(item => item.status === 0)
                if (divisionId) {
                    return districtList.filter(district => district.division_id === divisionId)
                }
            return districtList
        },
        view (item) {
           this.editItemId = item.id
        },
        remove (item) {
            this.changeStatus(seedFertilizerServiceBaseUrl, purchaseRequistionreject, item, 'fertilizer', 'purchaseRequistionlist')
        },
        accept (item) {
            this.changeStatus(seedFertilizerServiceBaseUrl, purchaseRequistionaccept, item, 'fertilizer', 'purchaseRequistionlist')
        },
        dataChange () {
            this.loadData()
        },
        async searchData () {
            this.loadData()
        },
        loadData () {
            const params = Object.assign({}, this.search, {
                fiscal_year_id: this.search.fiscal_year_id.value,
                sale_center_id: this.search.sale_center_id.value,
                fertilizer_name_id: this.search.fertilizer_name_id.value,
                page: this.pagination.currentPage,
                 per_page: this.pagination.perPage })
            this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
            RestApi.getData(seedFertilizerServiceBaseUrl, purchaseRequistionlist, params).then(response => {
                if (response.success) {
                    this.$store.dispatch('setList', this.getRelationalData(response.data))
                    this.paginationData(response.data)
                } else {
                   this.$store.dispatch('setList', '')
                }
                this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
            })
        },
        getRelationalData (data) {
            const orgList = this.$store.state.commonObj.organizationProfileList
            return data.data.map(item => {
                const orgObj = orgList.find(org => org.value === item.org_id)
                if (orgObj) {
                    const orgData = {
                        org_name: orgObj.text_en,
                        org_name_bn: orgObj.text_bn
                    }
                    return Object.assign({}, item, orgData)
                } else {
                    const orgData = {
                        org_name: '',
                        org_name_bn: ''
                    }
                    return Object.assign({}, item, orgData)
                }
            })
        },
        getPurchaseData (Id, type) {
            if (Id) {
                var data
                if (type === 'fiscalYear') {
                    data = this.$store.state.commonObj.fiscalYearList.find(item => item.value === Id)
                }
                if (type === 'salesCenter') {
                    data = this.$store.state.SeedsFertilizer.commonObj.salesCenterList.find(item => item.value === Id)
                }
                if (type === 'fertilizerType') {
                    data = this.$store.state.SeedsFertilizer.commonObj.fertilizerTypeList.find(item => item.value === Id)
                }
                if (type === 'fertilizerName') {
                    data = this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.find(item => item.value === Id)
                }
                if (type === 'unitName') {
                    data = this.$store.state.SeedsFertilizer.commonObj.measurementUnitList.find(item => item.value === Id)
                }
                if (data) {
                    return this.$i18n.locale === 'bn' ? data.text_bn : data.text_en
                } else {
                    return null
                }
            } else {
                return null
            }
        }
    }
}
</script>
