<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
          <template v-slot:body>
            <b-row>
              <b-col lg="12" sm="12">
                <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                  <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
                    <ValidationProvider name="Organization" vid='org_id' rules="required|min_value:1">
                      <b-form-group
                            class="row"
                            label-cols-sm="4"
                            :label="$t('globalTrans.organization')"
                            label-for="org_id"
                            slot-scope="{ valid, errors }"
                        >
                        <template v-slot:label>
                            {{ $t('globalTrans.organization')}} <span class="text-danger">*</span>
                        </template>
                        <b-form-select
                            plain
                            v-model="purchaseRequisitions.org_id"
                            :options="organizationtList"
                            id="org_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                        >
                            <template v-slot:first>
                            <b-form-select-option :value="0" disabled>{{ $t('globalTrans.select') }}</b-form-select-option>
                            </template>
                        </b-form-select>
                        <div class="invalid-feedback d-block">
                            {{ errors[0] }}
                        </div>
                        </b-form-group>
                        </ValidationProvider>
                      <ValidationProvider name="Category" vid='type' rules="required">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="warehouse_state"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('globalTrans.type')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-radio-group
                                v-model="purchaseRequisitions.type"
                                :options="options"
                                class="mb-3"
                                value-field="item"
                                text-field="name"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-radio-group>
                            <div class="invalid-feedback d-block">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>

                    <div>
                    <b-tabs content-class="mt-3 ml-4">
                    <b-tab :active="select"><p>
                        <ValidationProvider name="Company Name" vid="company_name" :rules="rule1">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="company_name"
                            slot-scope="{ valid, errors }"
                        >
                            <template v-slot:label>
                            {{$t('procurement.companyName')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                            id="company_name"
                            v-model="purchaseRequisitions.company_name"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" :rules="rule1">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="fiscal_year_id"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('seedFer.fiscal_year') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="purchaseRequisitions.fiscal_year_id"
                              :options="fiscalYearList"
                              id="fiscal_year_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="Current Date" vid="current_date" :rules="rule1">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="current_date"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{ $t('seedFer.current_date') }} <span class="text-danger">*</span>
                              </template>
                              <flat-pickr class="form-control"
                                v-model="purchaseRequisitions.current_date"
                                :placeholder="$t('globalTrans.select_date')"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></flat-pickr>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                          <ValidationProvider name="Sale Center" vid="sale_center_id" :rules="rule1">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="sale_center_id"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('seedFer.sales_center') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="purchaseRequisitions.sale_center_id"
                              :options="salesCenterList"
                              id="sale_center_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="Fertilizer Type" vid="fertilizer_type_id" :rules="rule1">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="fertilizer_type_id"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('fertilizerConfig.fertilizer_type') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="purchaseRequisitions.fertilizer_type_id"
                              :options="fertilizerTypeList"
                              id="fertilizer_type_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="Fertilizer Name" vid="fertilizer_name_id" :rules="rule1">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="fertilizer_name_id"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('fertilizerConfig.fertilizer_name') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="purchaseRequisitions.fertilizer_name_id"
                              :options="fertilizerNameList"
                              id="fertilizer_name_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="Quantity" vid="quantity" :rules="rule1">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="quantity"
                            slot-scope="{ valid, errors }"
                        >
                            <template v-slot:label>
                            {{$t('procurement.quantity')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                            id="mobile_no"
                            v-model="purchaseRequisitions.quantity"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="Unit" vid="unit_id" :rules="rule1">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="unit_id"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('irrigation_config.unit') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="purchaseRequisitions.unit_id"
                              :options="measurementUnitList"
                              id="unit_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="Remarks (En)"  vid="remarks" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="remarks"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{$t('procurement.remarks')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-textarea
                                    rows="4"
                                    id="remarks"
                                    v-model="purchaseRequisitions.remarks"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-textarea>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="Remarks (Bn)"  vid="remarks_bn" rules="">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="remarks_bn"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{$t('procurement.remarks_bn')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-textarea
                                    rows="6"
                                    id="remarks_bn"
                                    v-model="purchaseRequisitions.remarks_bn"
                                    :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-textarea>
                                <div class="invalid-feedback">
                                    {{ errors[0] }}
                                </div>
                            </b-form-group>
                        </ValidationProvider>

                    </p></b-tab>
                    <b-tab :active="select2"><p>
                        <ValidationProvider name="Dealer Registration" vid="dealer_com_farmer_id" :rules="rule2">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="farmer_name"
                            slot-scope="{ valid, errors }"
                        >
                            <template v-slot:label>
                            {{$t('procurement.dealerRegistration')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                            id="dealer_com_farmer_id"
                            v-model="purchaseRequisitions.dealer_com_farmer_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="Dealer Name" vid="dealer_name">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="dealer_name"
                            slot-scope="{ valid, errors }"
                        >
                            <template v-slot:label>
                            {{$t('procurement.dealerName')}} <span class="text-danger"></span>
                            </template>
                            <b-form-input
                            readonly
                            id="dealer_name"
                            v-model="purchaseRequisitions.dealer_name"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="Bill No." vid="dealer_name">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="dealer_name"
                            slot-scope="{ valid, errors }"
                        >
                            <template v-slot:label>
                            {{$t('procurement.billNo')}} <span class="text-danger"></span>
                            </template>
                            <b-form-input
                            readonly
                            id="dealer_name"
                            v-model="purchaseRequisitions.bill_no"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="Tin No." vid="dealer_name">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="dealer_name"
                            slot-scope="{ valid, errors }"
                        >
                            <template v-slot:label>
                            {{$t('procurement.tinNo')}} <span class="text-danger"></span>
                            </template>
                            <b-form-input
                            readonly
                            id="dealer_name"
                            v-model="purchaseRequisitions.tin_no"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="NID" vid="dealer_name">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="dealer_name"
                            slot-scope="{ valid, errors }"
                        >
                            <template v-slot:label>
                            {{$t('procurement.nId')}} <span class="text-danger"></span>
                            </template>
                            <b-form-input
                            readonly
                            id="dealer_name"
                            v-model="purchaseRequisitions.n_id"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                        <!-- <ValidationProvider name="Address" vid="dealer_name" :rules="rule2">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="dealer_name"
                            slot-scope="{ valid, errors }"
                        >
                            <template v-slot:label>
                            {{$t('globalTrans.address')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                            readonly
                            id="dealer_name"
                            v-model="purchaseRequisitions.address"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="Phone No." vid="dealer_name">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="dealer_name"
                            slot-scope="{ valid, errors }"
                        >
                            <template v-slot:label>
                            {{$t('globalTrans.mobile')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                            readonly
                            id="dealer_name"
                            v-model="purchaseRequisitions.phone_no"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider> -->
                        <ValidationProvider name="Fiscal Year" vid="fiscal_year_id" :rules="rule2">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="fiscal_year_id"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('seedFer.fiscal_year') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="purchaseRequisitions.fiscal_year_id"
                              :options="fiscalYearList"
                              id="fiscal_year_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="Current Date" vid="current_date" :rules="rule2">
                            <b-form-group
                              class="row"
                              label-cols-sm="4"
                              label-for="current_date"
                              slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                                {{ $t('seedFer.current_date') }} <span class="text-danger">*</span>
                              </template>
                                <flat-pickr class="form-control"
                                v-model="purchaseRequisitions.current_date"
                                :placeholder="$t('globalTrans.select_date')"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></flat-pickr>
                              <div class="invalid-feedback">
                                {{ errors[0] }}
                              </div>
                            </b-form-group>
                          </ValidationProvider>
                          <ValidationProvider name="Fertilizer Type" vid="fertilizer_type_id" :rules="rule2">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="fertilizer_type_id"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('fertilizerConfig.fertilizer_type') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="purchaseRequisitions.fertilizer_type_id"
                              :options="fertilizerTypeList"
                              id="fertilizer_type_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="Fertilizer Name" vid="fertilizer_name_id" :rules="rule2">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="fertilizer_name_id"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('fertilizerConfig.fertilizer_name') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="purchaseRequisitions.fertilizer_name_id"
                              :options="fertilizerNameList"
                              id="fertilizer_name_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="Quantity" vid="quantity" :rules="rule2">
                        <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="quantity"
                            slot-scope="{ valid, errors }"
                        >
                            <template v-slot:label>
                            {{$t('procurement.quantity')}} <span class="text-danger">*</span>
                            </template>
                            <b-form-input
                            id="mobile_no"
                            v-model="purchaseRequisitions.quantity"
                            :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="invalid-feedback">
                            {{ errors[0] }}
                            </div>
                        </b-form-group>
                        </ValidationProvider>
                        <ValidationProvider name="Unit" vid="unit_id" :rules="rule2">
                          <b-form-group
                            class="row"
                            label-cols-sm="4"
                            label-for="unit_id"
                            slot-scope="{ valid, errors }"
                            >
                            <template v-slot:label>
                            {{ $t('irrigation_config.unit') }} <span class="text-danger">*</span>
                            </template>
                            <b-form-select
                              plain
                              v-model="purchaseRequisitions.unit_id"
                              :options="measurementUnitList"
                              id="unit_id"
                              :state="errors[0] ? false : (valid ? true : null)"
                              >
                              <template v-slot:first>
                                <b-form-select-option :value="0" >{{$t('globalTrans.select')}}</b-form-select-option>
                              </template>
                            </b-form-select>
                            <div class="invalid-feedback">
                              {{ errors[0] }}
                            </div>
                          </b-form-group>
                        </ValidationProvider>
                    </p></b-tab>
                    </b-tabs>
                    </div>
                    <div class="row">
                      <div class="col-sm-3"></div>
                      <div class="col text-right">
                        <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                        &nbsp;
                        <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                      </div>
                    </div>
                  </b-form>
                </ValidationObserver>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { purchaseRequistionStore, purchaseRequistionUpdate, dealerInfo } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getCompanyFarmerData()
      this.purchaseRequisitions = tmp
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      purchaseRequisitions: {
        id: '',
        org_id: 0,
        type: 1,
        company_name: '',
        fiscal_year_id: 0,
        current_date: '',
        sale_center_id: '',
        fertilizer_type_id: '',
        dealer_name: '',
        fertilizer_name_id: '',
        unit_id: '',
        quantity: '',
        remarks: '',
        remarks_bn: '',
        dealer_com_farmer_id: '',
        bill_no: '',
        tin_no: '',
        n_id: '',
        address: '',
        phone_no: '',
        status: '1'
      },
      tab: 'active',
      tab2: '',
      rule1: '',
      rule2: '',
      select: true,
      select2: false,
      districtList: [],
      upazilaList: [],
      fertilizerNameList: [],
      options: [
          { item: 1, name: this.$t('procurement.company') },
          { item: 2, name: this.$t('procurement.dealer') }
        ]
    }
  },
  computed: {
    organizationtList: function () {
      return this.$store.state.commonObj.organizationProfileList
    },
    fiscalYearList: function () {
      const fiscalYears = this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 0)
      const tempData = fiscalYears.map(item => {
        if (this.$i18n.locale === 'bn') {
          return { text: item.text_bn, value: item.value }
        } else {
          return { text: item.text_en, value: item.value }
        }
      })
      return tempData
    },
    salesCenterList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.salesCenterList.filter(item => item.status === 1)
    },
    fertilizerTypeList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.fertilizerTypeList.filter(item => item.status === 1)
    },
    measurementUnitList: function () {
      return this.$store.state.SeedsFertilizer.commonObj.measurementUnitList.filter(item => item.status === 1)
    }
  },
  watch: {
    'purchaseRequisitions.type': function (newVal, oldVal) {
        if (newVal === 1) {
            this.select = true
            this.rule1 = ''
            this.select2 = false
            this.rule2 = ''
        } else {
            this.select = false
            this.rule1 = ''
            this.select2 = true
            this.rule2 = ''
        }
    },
    'purchaseRequisitions.dealer_com_farmer_id': function (newVal, oldVal) {
      this.districtList = this.getFarmerData(newVal)
    },
    'purchaseRequisitions.fertilizer_type_id': function (newVal, oldVal) {
      this.fertilizerNameList = this.getFertilizerList(newVal)
    }
  },
  methods: {
    getCompanyFarmerData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
     async createData () {
        var insertData = []
         if (this.purchaseRequisitions.type === 1) {
              const params = Object.assign({}, this.purchaseRequisitions, {
                    bill_no: '',
                    tin_no: '',
                    n_id: '',
                    dealer_name: '',
                    dealer_com_farmer_id: ''
                })
                insertData = params
         } else {
             const params = Object.assign({}, this.purchaseRequisitions, {
                   company_name: '',
                    sale_center_id: '',
                    remarks: '',
                    remarks_bn: ''
                })
                insertData = params
         }

      this.$store.dispatch('mutateCommonProperties', { loading: true })
      let result = null
      const loadinState = { loading: false, listReload: false }

      if (this.purchaseRequisitions.id) {
        result = await RestApi.putData(seedFertilizerServiceBaseUrl, `${purchaseRequistionUpdate}/${this.id}`, insertData)
      } else {
        result = await RestApi.postData(seedFertilizerServiceBaseUrl, purchaseRequistionStore, insertData)
      }

      loadinState.listReload = true

      this.$store.dispatch('mutateCommonProperties', loadinState)

      if (result.success) {
        this.$store.dispatch('mutateWarehouseProperties', { hasDropdownLoaded: false })
        this.$toast.success({
          title: 'Success',
          message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
          color: '#D6E09B'
        })

        this.$bvModal.hide('modal-4')
      } else {
        this.$refs.form.setErrors(result.errors)
      }
    },
    getFarmerData (regId = null) {
      RestApi.getData(seedFertilizerServiceBaseUrl, dealerInfo + '/' + regId).then(response => {
            if (response.success) {
                this.purchaseRequisitions.bill_no = response.data.bin_no
                this.purchaseRequisitions.tin_no = response.data.tin_no
                this.purchaseRequisitions.n_id = response.data.nid
                this.purchaseRequisitions.dealer_name = this.$i18n.locale === 'bn' ? response.data.name_bn : response.data.name
            }
        })
    },
    getFertilizerList (typeId = null) {
      return this.$store.state.SeedsFertilizer.commonObj.fertilizerNameList.filter(item => item.status === 1 && item.fertilizer_type_id === typeId)
    }
  }
}
</script>
